<template>
  <span v-if="methods">
    <span v-b-toggle.administration-add-organisation>
      <slot></slot>
    </span>

    <b-sidebar
      sidebar-class="big_sidebar"
      :title="GetTitle"
      id="administration-add-organisation"
      v-model="show"
      right
      backdrop
      no-slide
      lazy
      @shown="showSidebar"
      shadow
    >
      <div class="px-3 py-2 pt-0">
        <b-overlay :show="loading" rounded="sm">
          <b-alert show class="small-text">
            {{ $t("NOTE.ONLY_AS_SERVICE") }}
            <br />
            {{ $t("NOTE.ORGANISATION_OWN_NODE") }}
            <router-link :to="{ name: 'Administration.Nodes' }">{{
              $t("CLICK_HERE")
            }}</router-link>
          </b-alert>

          <div v-if="step == 1">
            <h5 class="mt-3">{{ $t("NAME") }}</h5>
            <div class="mt-1">
              <b-form-input
                trim
                required
                type="text"
                v-model="addOrganisationName"
                name="addOrganisationName"
              ></b-form-input>
            </div>

            <h5 class="mt-3">{{ $t("ORGANISATION.UNIQUE_IDENTIFIER") }}</h5>
            <div class="mt-1">
              <b-form-input
                trim
                required
                id="input-unique-identifier"
                type="text"
                v-model="addOrganisationUniqueIdentifier"
                name="addOrganisationUniqueIdentifier"
                :state="organisationRegistered"
              ></b-form-input>

              <b-form-invalid-feedback id="input-unique-identifier-feedback">
                {{ $t("ERROR.ALREADY_REGISTERED") }}
              </b-form-invalid-feedback>
            </div>

            <hr class="p-0 m-0 mt-2 mb-2" />

            <b-button
              :disabled="disabledStep2"
              @click="gotoStep2"
              variant="primary"
              class="btn-fill"
              >{{ $t("NEXT") }}</b-button
            >
          </div>
          <div v-if="step == 2">
            <h5 class="mt-3">{{ $t("EMAIL") }}</h5>
            <div class="mt-1">
              <b-form-input
                trim
                id="input-email"
                required
                type="text"
                v-model="addEmail"
                :state="emailRegistered"
                name="email"
              ></b-form-input>

              <b-form-invalid-feedback id="input-email-feedback">
                {{ $t("ERROR.ALREADY_REGISTERED") }}
              </b-form-invalid-feedback>
            </div>

            <h5 class="mt-3">{{ $t("NAME") }}</h5>
            <div class="mt-1">
              <b-form-input
                trim
                required
                type="text"
                v-model="addName"
                name="name"
              ></b-form-input>
            </div>

            <h5 class="mt-3">{{ $t("LANGUAGE.TITLE") }}</h5>
            <div class="mt-1">
              <b-form-select
                name="add_organisation_select_language"
                v-model="addLang"
              >
                <b-form-select-option
                  v-for="(value, key) in sefos_locales"
                  :key="key"
                  :value="key"
                >
                  {{ $t("LANGUAGE." + key) }}
                </b-form-select-option>
              </b-form-select>
            </div>

            <h5 class="mt-3">{{ $t("AUTH_METHODS.SELECT_METHOD") }}</h5>
            <div class="mt-1">
              
              <b-form-select
                v-model="selectedIndex"
                :options="options"
              ></b-form-select>
              
              <Pnr
                class="mt-2"
                required
                v-if="auth_type == 'se-eid'"
                v-model="addData"
              ></Pnr>

              <Identifier
                class="mt-2"
                :placeholder="$t('UNIQUE_IDENTIFIER')"
                v-if="showIdentifier"
                v-model="addData"
              ></Identifier>
            </div>

            <hr class="p-0 m-0 mt-2 mb-2" />

            <b-button @click="gotoStep1">{{ $t("BACK") }}</b-button>

            <b-button @click="skipStep2" variant="primary" class="btn-fill">{{
              $t("SKIP_THIS_STEP")
            }}</b-button>

            <b-button
              :disabled="!validEmail"
              @click="gotoStep3"
              variant="primary"
              class="btn-fill"
              >{{ $t("NEXT") }}</b-button
            >
          </div>
          <div v-if="step == 3">
            <Prices :prices="prices"></Prices>

            <hr class="p-0 m-0 mt-2 mb-2" />

            <b-button @click="gotoStep2">{{ $t("BACK") }}</b-button>
            <b-button
              :disabled="!validEmail"
              variant="primary"
              class="btn-fill"
              @click="createOrganisation"
              >{{ $t("SUBMIT") }}</b-button
            >
          </div>
        </b-overlay>
      </div>
    </b-sidebar>
  </span>
</template>
<script>
import Pnr from "@/components/Input/Pnr.vue";
import Identifier from "@/components/Input/Identifier.vue";
import Prices from "@/components/Input/Prices.vue";
export default {
  components: { Prices, Pnr, Identifier },
  data() {
    return {
      step: 1,
      methods: {},
      show: false,
      loading: false,
      selectedIndex: 0,
      selectedChildIndex: 0,
      childs: [],
      childs_options: [],
      organisationRegistered: null,
      emailRegistered: null,
      addData: "",
      addLang: this.$i18n.locale,
      addOrganisationUniqueIdentifier: "",
      addOrganisationName: "",
      addEmail: "",
      addName: "",
      auth_type: "password",
      auth_method: "",
      register_admin: 1,
      prices: {
        organisation_id: 0,
        currency: "SEK",
        license: 0,
        accounts: 0,
        n1: 0,
        n2: 0,
        n3: 0,
        n4: 0,
        n5: 0,
        service: 0,
        login_bankid_se: 0,
        login_freja: 0,
        login_freja_org: 0,
        verify_bankid_se: 0,
        verify_freja: 0,
        verify_sms: 0,
        sendgrid: 0,
        description: "",
      },
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      options: [],
    };
  },
  methods: {
    showSidebar() {
      this.getMethods();
    },
    changedAuthMethod(method) {
      this.auth_method = method;
    },
    async gotoStep1() {
      this.organisationRegistered = null;
      this.step = 1;
    },
    async gotoStep2() {
      let self = this;
      await this.$http
        .post(this.user.hostname + "/organisation/is-registered", {
          unique_identifier: this.addOrganisationUniqueIdentifier,
        })
        .then(function (result) {
          if (result.data == false) {
            self.step = 2;
            self.organisationRegistered = true;
          } else {
            self.organisationRegistered = false;
          }
        })
        .catch(function () {
          self.loading = false;
        });
    },
    async skipStep2() {
      this.step = 3;
      this.register_admin = 0;
      this.emailRegistered = true;
    },
    async gotoStep3() {
      let self = this;
      await this.$http
        .post(this.user.hostname + "/user/is-registered", {
          email: this.addEmail,
        })
        .then(function (result) {
          if (result.data == false) {
            self.register_admin = 1;
            self.step = 3;
            self.emailRegistered = true;
          } else {
            self.emailRegistered = false;
          }
        })
        .catch(function () {
          self.loading = false;
        });
    },
    changedAuth() {
      this.addData = "";
    },
    showModal() {
      this.modalView = true;
    },
    async processMethods() {
      this.options = [];
      for (let ix = 0; ix < this.methods.length; ix++) {
        let method = this.methods[ix];
        let add = true;
        if (
          method.admin == 1 &&
          this.user.information.organisation.is_admin == 0
        ) {
          add = false;
        }
        let optionName = "";
        if (method.custom_name != "") {
          optionName = method.custom_name;
        } else {
          optionName = this.$t("AUTH_METHODS." + method.auth_type);
        }
        if (add) {
          if (ix == 0) {
            this.auth_type = method.auth_type;
          }
          this.options.push({
            value: ix,
            text: optionName,
          });
        }
      }
      if (this.selectedIndex == 0) {
        this.calculateMethods(0);
      }
    },
    async getMethods() {
      let self = this;
      self.methods = [];
      await this.$http
        .get(this.user.hostname + "/invite/methods/list")
        .then(function (result) {
          self.methods = result.data;
          self.processMethods();
        })
        .catch(function () {
          self.loading = false;
        });
    },
    createOrganisation: function () {
      let self = this;
      this.loading = true;
      let AuthDataDTO = {
        pnr: "",
        identifier: "",
        password1: "",
        password2: "",
      };
      if (this.auth_type == "se-eid") {
        AuthDataDTO.pnr = this.addData;
      }
      if (this.showIdentifier) {
        AuthDataDTO.identifier = this.addData;
      }
      let payload = {
        email: this.addEmail,
        name: this.addName,
        data: AuthDataDTO,
        language: this.addLang,
        organisation_name: this.addOrganisationName,
        organisation_unique_identifier: this.addOrganisationUniqueIdentifier,
        prices: this.prices,
        auth_type: this.auth_type,
        auth_method: this.auth_method,
        register_admin: this.register_admin,
      };
      this.$http
        .post(this.user.hostname + "/administration/organisations/add", payload)
        .then(function () {
          self.show = false;
          self.loading = false;
          self.$emit("invitedResult");
          self.$noty.info(self.$t("ADDED"));
        })
        .catch(function () {
          self.loading = false;
        });
    },
    calculateMethods(newVal) {
      this.childs = [];
      this.childs_options = [];
      let method = this.methods[newVal];
      this.auth_type = method.auth_type;
      /*if (method.auth_type == "custom") {
        this.auth_method = method.auth_method;
      } else {
        //if (method.children.length == 0) {
        this.auth_method = method.auth_method;
        //} else {
          if (method.children.length > 1) {
            this.childs_options.push({
              value: null,
              text: this.$t("ALL"),
            });
            this.selectedChildIndex = null;
          }
          for (let ax = 0; ax < method.children.length; ax++) {
            let childmethod = method.children[ax];
            this.childs.push(childmethod);
            this.childs_options.push({
              value: ax,
              text: this.$t("AUTH_METHODS." + childmethod.auth_method),
            });
            if (childmethod.auth_method == this.auth_method) {
              this.selectedChildIndex = ax;
            }
          }
        }
      }*/
    },
  },
  computed: {
    disabledStep2: function () {
      if (
        this.addOrganisationName != "" &&
        this.addOrganisationUniqueIdentifier != ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    disabledStep3: function () {
      if (this.addEmail != "" && this.addName != "") {
        return false;
      } else {
        return true;
      }
    },
    adminOptions: function () {
      if (this.user.is_admin == 2) {
        return [
          { value: 0, text: this.$t("ADMINLEVEL.0") },
          { value: 1, text: this.$t("ADMINLEVEL.1") },
          { value: 2, text: this.$t("ADMINLEVEL.2") },
        ];
      } else {
        return [
          { value: 0, text: this.$t("ADMINLEVEL.0") },
          { value: 1, text: this.$t("ADMINLEVEL.1") },
        ];
      }
    },
    validEmail: function () {
      if (this.addOrganisationName == "") {
        return false;
      }
      if (this.addOrganisationUniqueIdentifier == "") {
        return false;
      }
      if (this.register_admin == 1) {
        if (this.addName == "") {
          return false;
        }
        if (this.addEmail == "") {
          return false;
        } else {
          return this.validateEmail(this.addEmail);
        }
      } else {
        return true;
      }
    },
    showIdentifier() {
      if (this.auth_type == "custom") {
        return this.methods[this.selectedIndex].needs_identifier == 1;
      }
      return this.auth_type == "freja-org";
    },
    GetTitle() {
      if (this.step == 1) {
        return this.$t("ORGANISATION.TITLE");
      }
      if (this.step == 2) {
        return this.$t("ORGANISATION.ADMINISTRATOR");
      }
      if (this.step == 3) {
        return this.$t("PRICES");
      }
      return "";
    },
  },
  watch: {
    selectedIndex: function (newVal) {
      this.calculateMethods(newVal);
    },
    selectedChildIndex: function (newVal) {
      if (newVal == null) {
        this.auth_method = "";
      } else {
        this.auth_method = this.childs[newVal].auth_method;
      }
    },
  },
  mounted() {},
};
</script>
<style></style>
